import React from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Toggler from './Toggler';

import { rhythm } from '../utils/typography';

class Layout extends React.PureComponent {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    const currentYear = new Date().getFullYear();
    const footerString = `© ${currentYear} Copyright, Shubham Bhardwaj. All rights reserved.`;

    let header;

    if (location.pathname === rootPath) {
      header = <Link to="/home">Home</Link>;
    } else {
      header = (
        <div className="d-flex justify-content-between">
          <h5 className="blog-post-header-title">
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`
              }}
              to="/"
            >
              {title}
            </Link>
          </h5>
          <Toggler />
        </div>
      );
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>{footerString}</footer>
      </div>
    );
  }
}

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Layout;
